import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaFileExcel, FaFilePdf, FaFileExport } from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ExportOptions = ({ data, fileName }) => {
  const [showOptions, setShowOptions] = useState(false);

  const exportToCSV = () => {
    const headers = ['S.No', 'Name', 'Email', 'Joining Date', 'Last Billing Date', 'Current Status', 'Selected Seat', 'Selected Slots'];
    const csvContent = [
      headers.join(','),
      ...data.map((item, index) => [
        index + 1,
        item.name,
        item.email,
        item.joinDate,
        item.lastBillingDate,
        item.billingCycles[item.currentCycleIndex].status,
        item.selectedSeat || 'Not selected',
        item.selectedSlots || 'N/A'
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${fileName}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['S.No', 'Name', 'Email', 'Joining Date', 'Last Billing Date', 'Current Status', 'Selected Seat', 'Selected Slots']],
      body: data.map((item, index) => [
        index + 1,
        item.name,
        item.email,
        item.joinDate,
        item.lastBillingDate,
        item.billingCycles[item.currentCycleIndex].status,
        item.selectedSeat || 'Not selected',
        item.selectedSlots || 'N/A'
      ]),
    });
    doc.save(`${fileName}.pdf`);
  };

  return (
    <div className="relative">
      <button
        className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-200 flex items-center"
        onClick={() => setShowOptions(!showOptions)}
      >
        <FaFileExport className="mr-2" />
        Export Data
      </button>
      {showOptions && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-10">
          <button
            onClick={() => {
              exportToCSV();
              setShowOptions(false);
            }}
            className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-200 flex items-center"
          >
            <FaFileExcel className="mr-2 text-green-600" />
            Export to CSV
          </button>
          <button
            onClick={() => {
              exportToPDF();
              setShowOptions(false);
            }}
            className="w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-200 flex items-center"
          >
            <FaFilePdf className="mr-2 text-red-600" />
            Export to PDF
          </button>
        </div>
      )}
    </div>
  );
};

ExportOptions.propTypes = {
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired
};

export default ExportOptions;
